import React, {
    useRef,
    useLayoutEffect,
    useCallback,
    useState,
    useEffect,
} from "react"
import * as ScrollArea from "@radix-ui/react-scroll-area"

function Scroll({
    children,
    neverHide = false,
    scrollHideDelay,
    scrollbarClassName = "",
    itemCount = 0,
}) {
    const effectiveScrollHideDelay = neverHide
        ? false
        : scrollHideDelay !== undefined
        ? scrollHideDelay
        : 600

    const viewportRef = useRef(null)
    const contentRef = useRef(null)
    const prevHeightRef = useRef(null)
    const resizeObserverRef = useRef(null)
    const curScrollTop = useRef(0)
    const currentItemCount = useRef(itemCount)

    // scroll auto hide
    const [isScrollVisible, setIsScrollVisible] = useState(false)
    const timeoutRef = useRef(null)

    const smoothScroll = (element, target, duration) => {
        const start = element.scrollTop
        const change = target - start
        const startTime = performance.now()

        const animateScroll = (currentTime) => {
            const elapsedTime = currentTime - startTime
            const progress = Math.min(elapsedTime / duration, 1)
            element.scrollTop = start + change * easeInOutCubic(progress)

            if (progress < 1) {
                requestAnimationFrame(animateScroll)
            }
        }

        requestAnimationFrame(animateScroll)
    }

    // Функция плавности
    const easeInOutCubic = (t) =>
        t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1

    const handleResize = useCallback(() => {
        if (viewportRef.current && contentRef.current) {
            const newHeight = contentRef.current.offsetHeight

            if (currentItemCount.current === 0 || itemCount === 0) {
                currentItemCount.current = itemCount
                prevHeightRef.current = newHeight
                return
            }

            if (prevHeightRef.current !== null) {
                if (currentItemCount.current !== itemCount) {
                    const scrollDiff = newHeight - prevHeightRef.current
                    if (scrollDiff > 0) {
                        curScrollTop.current += scrollDiff
                        viewportRef.current.scrollTop = curScrollTop.current
                        // const targetScrollTop =
                        // curScrollTop.current + scrollDiff
                        smoothScroll(viewportRef.current, 0, 600) // 300ms для анимации
                        // curScrollTop.current = targetScrollTop
                    }

                    currentItemCount.current = itemCount
                }
            }
            prevHeightRef.current = newHeight
        }
    }, [itemCount])

    useLayoutEffect(() => {
        if (itemCount === 0) return

        if (contentRef.current) {
            resizeObserverRef.current = new ResizeObserver(handleResize)
            resizeObserverRef.current.observe(contentRef.current)
        }

        return () => {
            if (resizeObserverRef.current) {
                resizeObserverRef.current.disconnect()
            }
        }
    }, [handleResize, itemCount === 0])

    useEffect(() => {
        if (neverHide) {
            setIsScrollVisible(true)
        }
    }, [neverHide])

    const handleScroll = useCallback(() => {
        if (viewportRef.current) {
            // console.log("update curScrollTop.current: ", curScrollTop.current)
            curScrollTop.current = viewportRef.current.scrollTop
        }

        // works with timeout
        if (!neverHide) {
            setIsScrollVisible(true)

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }

            timeoutRef.current = setTimeout(() => {
                setIsScrollVisible(false)
            }, effectiveScrollHideDelay)
        }
    }, [])

    return (
        <ScrollArea.Root
            className="h-full w-full overflow-hidden"
            scrollHideDelay={effectiveScrollHideDelay}
            // type={neverHide ? "always" : "scroll"}
            type="always"
        >
            <ScrollArea.Viewport
                className="h-full w-full"
                ref={viewportRef}
                onScrollCapture={handleScroll}
            >
                <div ref={contentRef}>{children}</div>
            </ScrollArea.Viewport>

            <ScrollArea.Scrollbar
                className={`flex select-none touch-none p-0.5  data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5 ${scrollbarClassName} transition-opacity duration-300 ease-in-out
          ${isScrollVisible ? "opacity-100" : "opacity-0"}`}
                orientation="vertical"
            >
                <ScrollArea.Thumb className="flex-1 bg-cararra-900 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
            </ScrollArea.Scrollbar>
        </ScrollArea.Root>
    )
}

export default Scroll
