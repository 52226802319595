import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import MyButton from "../ui/MyButton"
import { Trash2 } from "lucide-react"
import { useQueryClient } from "@tanstack/react-query"
import useUserStore from "../User/UserStore"
import { isDemoEntry } from "./helpers"
import Scroll from "../ui/Scroll"

import {
    useDiaryQuery,
    useCreateDiaryMutation,
    useDeleteDiaryMutation,
} from "./DiaryService"
import useDiaryStore from "./DiaryStore"
import { useTranslation } from "react-i18next"
import { isRtl } from "../Lang/Definitions"

function DiaryPage() {
    const { isLoading, error, data } = useDiaryQuery()
    const setDiaryNotes = useDiaryStore((state) => state.setDiaryNotes)
    const diaryNotes = useDiaryStore((state) => state.diaryNotes)
    const dates = useDiaryStore((state) => state.dates)
    const notes = useDiaryStore((state) => state.notes)
    const createDiaryNote = useDiaryStore((state) => state.createDiaryNote)
    const deleteDiaryNote = useDiaryStore((state) => state.deleteDiaryNote)
    const createNewDiary = useCreateDiaryMutation()
    const queryClient = useQueryClient()
    const lang = useUserStore((state) => state.lang)

    useEffect(() => {
        if (data) {
            setDiaryNotes(data)
        }
    }, [data])

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ["diaries"] })
        // queryClient.invalidateQueries({ queryKey: ['diary'], refetchType: 'none' })
    }, [lang])

    const { t, i18n } = useTranslation("common", "diary")

    const createNewHandler = () => {
        createNewDiary.mutate(
            {},
            {
                onSuccess: (data) => {
                    navigate(`/diary/${data.id}`)
                    createDiaryNote(data)
                },
            }
        )
    }

    const DeleteDiaryNote = ({ diaryNote, className }) => {
        const useDeleteDiary = useDeleteDiaryMutation(diaryNote.id)

        const deleteDiaryNoteHandler = () => {
            useDeleteDiary.mutate(
                {
                    id: diaryNote.id,
                },
                {
                    onSuccess: (data) => {
                        deleteDiaryNote(data.id)
                    },
                }
            )
        }

        return (
            <MyButton
                onClick={deleteDiaryNoteHandler}
                loading={useDeleteDiary.isPending}
                isIcon={true}
                className={className}
            >
                <Trash2 size={16} />
            </MyButton>
        )
    }

    return (
        <div className="container mx-auto px-4 flex flex-row space-x-4 justify-center">
            <div className="flex-grow px-4 h-screen">
                <Scroll scrollbarClassName="pb-16 pt-32 mt-1.5">
                    <div className="flex flex-col space-y-4 my-16 pr-4">
                        <div className="flex flex-row space-x-4">
                            <div className="flex flex-col w-16 items-center flex-shrink-0"></div>
                            <div className="flex-grow flex flex-col space-y-4">
                                <MyButton
                                    onClick={createNewHandler}
                                    accent
                                    big
                                    gaCategory="Diary"
                                    gaAction="Create Entry"
                                    gaLabel="New Entry Button"
                                >
                                    {t("diary:createNew")}
                                </MyButton>
                            </div>
                        </div>
                        {Array.from(dates.entries()).map(([date, dateInfo]) => (
                            <div className="flex flex-row space-x-4" key={date}>
                                <div className="flex flex-col w-16 items-center flex-shrink-0">
                                    <div>{dateInfo.month}</div>
                                    <div className="text-accent text-4xl font-semibold">
                                        {dateInfo.day}
                                    </div>
                                </div>

                                <div className="flex-grow flex flex-col space-y-4">
                                    {notes.get(date) &&
                                        notes.get(date).map((note) => (
                                            <div
                                                className="relative group"
                                                key={note.id}
                                            >
                                                <Link to={`/diary/${note.id}`}>
                                                    <EntryPreview entry={note}>
                                                        <div
                                                            className="rounded-3xl shadow-lg p-6 px-8 bg-white text-lg relative whitespace-pre-line"
                                                            dir={
                                                                isRtl(note.lang)
                                                                    ? "rtl"
                                                                    : "ltr"
                                                            }
                                                        >
                                                            {note.content || (
                                                                <span className="text-gray-400">
                                                                    {t(
                                                                        "diary:blankEntry"
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </EntryPreview>
                                                </Link>

                                                <div
                                                    className={`absolute top-5 ${
                                                        isRtl(note.lang)
                                                            ? "left-5"
                                                            : "right-5"
                                                    } opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
                                                >
                                                    <DeleteDiaryNote
                                                        diaryNote={note}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </Scroll>
            </div>
            <div className="w-80 flex-shrink-0 mt-16">
                <div className="whitespace-pre-line text-base">
                    {t("diary:motivalionSpeech")}
                </div>

                <div className="p-4 mt-8 bg-accent text-white font-semibold text-sm rounded-lg">
                    <p>
                        Our project is currently in an early public alpha stage.
                        We're working hard to make the service smooth and
                        stable, but you might encounter some rough edges.
                    </p>
                    <p>
                        If something doesn't work as expected, try refreshing
                        the page.
                    </p>
                    <p>Thank you for your understanding and support!</p>
                </div>
            </div>
        </div>
    )
}

export default DiaryPage

const EntryPreview = ({ entry, children }) => {
    const { t } = useTranslation("common", "diary")

    if (!isDemoEntry(entry)) {
        return children
    }

    return (
        <div
            className="rounded-3xl bg-amber-600  text-lg relative whitespace-pre-line"
            dir={isRtl(entry.lang) ? "rtl" : "ltr"}
        >
            <div className="p-4 px-8 pr-16 text-white font-semibold text-sm">
                {t("diary:demoDisclamer")}
            </div>
            {children}
        </div>
    )
}

export const enDiaryResources = {
    diary: {
        createNew: "Add entry",
        blankEntry: "Blank entry",
        motivalionSpeech:
            "Regular writing practice in the language you're learning can be the key to rapid progress. Research shows that systematically engaging in writing exercises not only expands vocabulary but also improves grammatical skills.\n\nWhen we write, we actively use the language, which helps us better remember new words and structures. Many successful polyglots note that daily writing practice was instrumental in helping them achieve fluency in their target languages.",
        demoDisclamer:
            "This entry is an example of what notes in your diary look like. Click on it to learn about the main features of the application and how Gloisia helps you find and correct mistakes.\n You can delete this entry at any time by simply clicking on the trash icon.",
    },
}

export const ruDiaryResources = {
    diary: {
        createNew: "Добавить запись",
        blankEntry: "Пустая запись",
        motivalionSpeech:
            "Регулярная практика письма на изучаемом языке может стать ключом к быстрому прогрессу. Исследования показывают, что систематическое выполнение письменных упражнений не только расширяет словарный запас, но и улучшает грамматические навыки.\n\nКогда мы пишем, мы активно используем язык, что помогает лучше запоминать новые слова и конструкции. Многие успешные полиглоты отмечают, что именно ежедневная письменная практика помогла им достичь свободного владения языком.",
        demoDisclamer:
            "Эта запись - пример того, как выглядят заметки в вашем дневнике. Нажмите на неё, чтобы узнать об основных функциях приложения и о том, как Gloisia помогает находить и исправлять ошибки.\nВы можете удалить эту запись в любое время, просто нажав на значок корзины.",
    },
}
