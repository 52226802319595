import React from "react"
// import ReactGA from "react-ga4"
import Spinner from "./Spinner" // Замените на актуальный путь

const MyButton = ({
    children,
    onClick,
    loading = false,
    disabled = false,
    className = "",
    isIcon = false,
    isLink = false,
    accent = false,
    big = false,
    fontColorClass = "",

    // Google Analytics
    gaCategory,
    gaAction,
    gaLabel,
    ...props
}) => {
    const baseClassName = [
        "font-mulish rounded-lg transition duration-300 w-fit relative focus:outline-none",
        big ? "text-lg" : "text-xs",
        isIcon
            ? big
                ? "p-3"
                : "p-2 bg-neutral"
            : big
            ? "px-8 py-3"
            : "px-3 py-2",
        isIcon ? "" : "border",
        fontColorClass != ""
            ? fontColorClass
            : accent
            ? "text-accent font-semibold"
            : "text-secondary",
        ,
        disabled || loading ? "opacity-50 cursor-not-allowed" : "",
        className,
    ].join(" ")

    const hoverClassName =
        !disabled && !loading
            ? isIcon
                ? "hover:text-primary hover:bg-emphasis hover:shadow-inner"
                : accent
                ? "hover:text-white hover:shadow-md hover:bg-accent"
                : "hover:text-gray-950 hover:border-gray-900"
            : ""

    const borderClassName = !isIcon
        ? accent
            ? "border-accent"
            : "border-gray-400"
        : ""

    const handleClick = (e) => {
        // Отправляем событие в GA, если предоставлены соответствующие пропсы
        if (window.gtag && gaCategory && gaAction) {
            window.gtag("event", gaAction, {
                event_category: gaCategory,
                event_label: gaLabel,
            })
        }

        // Вызываем оригинальный обработчик onClick, если он предоставлен
        if (onClick) {
            onClick(e)
        }
    }

    const ButtonContent = () => (
        <>
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <Spinner />
                </div>
            )}
            <span className={loading ? "invisible" : ""}>{children}</span>
        </>
    )

    const buttonProps = {
        className: `${baseClassName} ${hoverClassName} ${borderClassName}`,
        disabled: disabled || loading,
        ...props,
    }

    if (isLink) {
        return (
            <a href={props.href} {...buttonProps}>
                <ButtonContent />
            </a>
        )
    } else {
        return (
            <button onClick={handleClick} {...buttonProps}>
                <ButtonContent />
            </button>
        )
    }
}

export default MyButton
